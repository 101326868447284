import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import Config from "data/config";
import CloseIcon from "@mui/icons-material/Close";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";
import { StyledButton } from "./styles";

export interface AppListModalProps {
  modalIsOpen: boolean;
  onClose: () => void;
}

export default function ContactModal({
  modalIsOpen,
  onClose,
}: AppListModalProps): JSX.Element {
  const whatsappLinkSupport = createLinkWhatsapp(
    Config.contact.whatsapp.number,
    'Olá, preciso de ajuda com um serviço/produto da iTop Telecom.'
  );

  return (
    <Dialog
      open={modalIsOpen}
      onClose={onClose}
      disableScrollLock
      PaperProps={{
        sx: {
          minWidth: "385px",
          backgroundColor: "#ffffff",
          borderRadius: "25px",
          padding: "0rem 1.5rem",
          boxShadow:
            "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          "@media (max-width: 850px)": {
            minWidth: "unset",
            width: "385px",
            padding: "1rem",
          },
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", top: 8, right: 8, color: "#000000" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent>
        <Box textAlign="center" pb={3} fontSize="18px" fontWeight="700">
          Selecione a opção de contato:
        </Box>
        <Stack spacing={2}>
          <StyledButton onClick={() => openLink(whatsappLinkSupport, "_blank")}>
            Central de atendimento
          </StyledButton>
          <StyledButton
            onClick={() => openLink('https://tintim.link/whatsapp/0d39020f-1616-40a8-ba1f-ad6eaebae0e2/b33d19ab-f7ec-463b-917d-b12cec962e4c', "_blank")}
          >
            Quero contratar
          </StyledButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
