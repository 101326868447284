import { Box, Grid, Stack, CardContent } from "@mui/material";
import { Title, SubTitle, ServicesCard, ResponsiveIcon, Label } from "./styles";
import { openLink } from "helpers/ManageLinks";
export default function Services() {
  const services: any = [
    {
      label: "Segunda via <br />de fatura",
      icon: "/images/services/ic1.png",
      link: "/login",
    },
    {
      label: "Devolução de equipamento",
      icon: "/images/services/ic2.png",
      link: "/cliente/devolucao-equipamentos",
    },
    {
      label: "Trabalhe <br /> Conosco",
      icon: "/images/services/ic3.png",
      link: "/trabalhe-conosco",
    },
    {
      label: "Desbloqueio cortesia",
      icon: "/images/services/ic4.png",
      link: "/login",
    },
    {
      label: "Ouvidoria",
      icon: "/images/services/ic5.png",
      link: "/ouvidoria",
    },
  ];

  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(180deg, #FF7C06 0%, #F9963D 101.45%)",
      }}
      py={8}
    >
      <Grid display="flex" container justifyContent={"center"}>
        <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5.5} textAlign="center">
          <Grid
            textAlign={{
              xs: "center",
              sm: "center",
              md: "center",
              lg: "left",
              xl: "left",
            }}
          >
            <Title>Serviços e área do cliente</Title>
            <Grid
              container
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "start",
                xl: "start",
              }}
              pt={{ xs: 3, sm: 3, md: 3, lg: 5, xl: 5 }}
            >
              <Grid item xs={9.5} sm={7.5} md={6} lg={7.5} xl={6}>
                <SubTitle>
                  Acesse nossos principais serviços, como a emissão de segunda
                  via de fatura e devolução de equipamento.
                </SubTitle>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={11.5}
          sm={8.5}
          md={6.5}
          lg={5}
          xl={5}
          textAlign="center"
          pt={{ xs: 5, sm: 5, md: 5, lg: 1, xl: 1 }}
          pb={1}
        >
          <Grid
            container
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "center",
              lg: "end",
              xl: "end",
            }}
          >
            <Grid item xs={11.5} sm={11.5} md={11} lg={11} xl={9}>
              <Stack
                spacing={{ xs: 0.5, sm: 1, md: 1, lg: 1, xl: 1 }}
                direction="row"
                textAlign="center"
              >
                {services.slice(0, 3).map((it: any, index: number) => (
                  <ServicesCard
                    sx={{
                      borderRadius: "20px",
                      boxShadow: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => openLink(it.link)}
                    key={index}
                  >
                    <CardContent style={{ textAlign: "start" }}>
                      <ResponsiveIcon src={it.icon} alt={it.icon} />
                      <Label dangerouslySetInnerHTML={{ __html: it.label }} />
                    </CardContent>
                  </ServicesCard>
                ))}
              </Stack>
              <Stack
                pt={{ xs: 0.5, sm: 1, md: 1, lg: 1, xl: 1 }}
                spacing={{ xs: 0.5, sm: 1, md: 1, lg: 1, xl: 1 }}
                direction="row"
                textAlign="center"
              >
                {services.slice(3, 5).map((it: any, index: number) => (
                  <ServicesCard
                    sx={{
                      borderRadius: "20px",
                      boxShadow: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => openLink(it.link)}
                    key={index}
                  >
                    <CardContent style={{ textAlign: "start" }}>
                      <ResponsiveIcon src={it.icon} alt={it.icon} />
                      <Label dangerouslySetInnerHTML={{ __html: it.label }} />
                    </CardContent>
                  </ServicesCard>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
