const appStandards: any = [
  {
    name: "Looke",
    description:
      "Looke é uma plataforma brasileira de streaming de filmes e séries premiados por assinatura.",
      img: '/images/apps/standart/looke.jpg',
  },
  {
    name: "Curta!On",
    description:
      "O Curta! é um canal independente, dedicado às artes, cultura e humanidades. São assuntos do canal: música, cinema, dança, teatro, artes visuais, história e outros.",
      img: '/images/apps/standart/curtaon.png',
  },
  {
    name: "Pequenos Leitores",
    description:
      "Incentive a paixão pela leitura no seu filho com histórias encantadoras e educativas que estimulam a imaginação e o aprendizado, tornando a leitura uma aventura divertida e enriquecedora.",
      img: '/images/apps/standart/pequenos-leitores.jpg',
  },
  {
    name: "Play Kids",
    description:
      "Ofereça diversão, aprendizado e muitos conteúdos para o seu filho com o melhor conteúdo infantil.",
      img: '/images/apps/standart/playkids.jpg',
  },
  {
    name: "No ping",
    description:
      "Reduza o lag, o ping e o jitter para alcançar um desempenho impecável e uma experiência sem interrupções.",
      img: '/images/apps/standart/no-ping.jpg',
  },
  {
    name: "ubook plus",
    description:
      "Tenha uma grande diversidade de títulos, entre audiobooks, ebooks e podcasts para se entreter e aprender.",
      img: '/images/apps/standart/ubook.png',
  },
  {
    name: "hube revistas",
    description:
      "Um aplicativo para você se manter bem-informado a qualquer hora e em qualquer lugar.",
      img: '/images/apps/standart/hube-revistas.jpg',
  },
  {
    name: "O jornalista",
    description:
      "Acesse notícias de qualidade, análises profundas e reportagens exclusivas.",
      img: '/images/apps/standart/o-jornalista.jpg',
  },
  {
    name: "+Leitura",
    description:
      "Descubra uma vasta seleção de livros e conteúdos que vão ampliar seus horizontes e despertar sua paixão pela leitura.",
      img: '/images/apps/standart/leitura.jpg',
  },
  {
    name: "Social Comics",
    description: "Plataforma de quadrinhos digitais.",
    img: '/images/apps/standart/social_comics.png',
  },
  {
    name: "360 Leitura",
    description:
      "Explore uma coleção abrangente de livros e conteúdos que vão desde clássicos até lançamentos contemporâneos.",
      img: '/images/apps/standart/360-leitura.jpg',
  },
  {
    name: "Playlist",
    description:
      "Descubra e curta músicas selecionadas para cada momento do seu dia.",
      img: '/images/apps/standart/playlist.jpg',
  },
  {
    name: "Kaspersky",
    description:
      "Mais do que um antivírus. Obtenha tecnologia de segurança de ponta que protege tudo o que você faz online.",
      img: '/images/apps/standart/kapersky.jpg',
  },
  {
    name: "Fluid",
    description:
      "Um serviço de assinatura que disponibiliza conteúdo digital para ajudar no equilíbrio físico, mental e emocional.",
      img: '/images/apps/standart/fluid.png',
  },
  {
    name: "+Q Nutri",
    description:
      "O +Q Nutri é um produto em que você aprende o que comer, quando comer e como evitar determinados alimentos.",
    img: "/images/apps/premium/queima_diaria.jpg",
  },
  {
    name: "Zen App",
    description:
      "Práticas guiadas para reduzir o estresse, aumentar a concentração e promover o bem-estar.",
      img: '/images/apps/standart/zen-logo.jpg',
  },
  {
    name: "Estuda+",
    description:
      "Recursos educacionais de qualidade para ajudar você a alcançar seus objetivos acadêmicos.",
      img: '/images/apps/standart/estuda.jpg',
  },
];

export default appStandards;
