import { Button } from "@mui/material";
import styled from "styled-components";


export const StyledButton = styled(Button)`
  && {
    color: white;
    background-color: #2D3E91;
    text-transform: none;
    font-family: 'Mulish', sans-serif;
    font-size: 17px;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px;

    &:hover {
      background-color: #ff7c06;
      color: black;
    }
  }
`;