import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Grid,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import appsPremiums from "data/apps-premiums";
import appStandards from "data/apps-standarts";

export interface AppListModalProps {
  modalIsOpen: boolean;
  onClose: () => void;
}

export default function AppListModal({
  modalIsOpen,
  onClose,
}: AppListModalProps): JSX.Element {
  return (
    <Dialog
      open={modalIsOpen}
      onClose={onClose}
      disableScrollLock
      PaperProps={{
        sx: {
          minWidth: "75%",
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "0rem 1.5rem",
          boxShadow:
            "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          "@media (max-width: 850px)": {
            minWidth: "unset",
            width: "90%",
            padding: "1rem",
          },
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", fontSize: "25px", color: "#FF7C06" }}
          >
            Lista de Apps
          </Typography>
        </DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8, color: "#000000" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", fontSize: "25px", color: "#FF7C06" }}
            >
              Apps Standard
            </Typography>
          </Grid>
          {appStandards.map((standart: any, index: number) => (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} pt={3} key={index}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "16px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  width: "100%",
                  maxWidth: "400px",
                  height: "175px", // Altura fixa adicionada
                }}
              >
                <Avatar
                  alt="App Logo"
                  src={standart.img}
                  style={{
                    width: "95px",
                    height: "95px",
                    marginRight: "16px", // Espaçamento equivalente a 16px
                  }}
                />

                {/* Informações do Card */}
                <Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: "600",
                      fontSize: "15px",
                      marginBottom: "4px",
                    }}
                  >
                    {standart.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      color: "#9c9c9c",
                      fontSize: "13px",
                      lineHeight: "1.5",
                      fontWeight: "400",
                    }}
                  >
                    {standart.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
          <Box width="100%" py={4} />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", fontSize: "25px", color: "#FF7C06" }}
            >
              Apps Premium
            </Typography>
          </Grid>
          {appsPremiums.map((premium: any, index: number) => (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4} pt={3} key={index}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "16px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  width: "100%",
                  maxWidth: "400px",
                  height: "175px", // Altura fixa adicionada
                }}
              >
                <Avatar
                  alt="App Logo"
                  src={premium.img}
                  style={{
                    width: "95px",
                    height: "95px",
                    marginRight: "16px", // Espaçamento equivalente a 16px
                  }}
                />

                {/* Informações do Card */}
                <Box>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: "600",
                      fontSize: "15px",
                      marginBottom: "4px",
                    }}
                  >
                    {premium.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{
                      color: "#9c9c9c",
                      fontSize: "13px",
                      lineHeight: "1.5",
                      fontWeight: "400",
                    }}
                  >
                    {premium.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
