import { StyledIcon, StyledTooltip } from "./styles";
import { Tooltip } from "@mui/material";
import { useState } from "react";
import ContactModal from "components/ContactModal";

export default function ChatButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ContactModal
        modalIsOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <Tooltip
        title="Contrate pelo WhatsApp"
        placement="bottom-start"
        componentsProps={StyledTooltip}
        arrow
      >
        <StyledIcon>
          <div onClick={() => setIsModalOpen(true)}>
            <img
              src="/images/icons/whatsapp-circle.png"
              alt="icon-linkedin"
              width="75px"
            />
          </div>
        </StyledIcon>
      </Tooltip>
    </>
  );
}
